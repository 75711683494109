.loading-section {
    display: flex;
    justify-content: center;
    font-size: 10px;
    padding: 50px;
}

.loading-section .dot {
    position: relative;
    width: 2em;
    height: 2em;
    margin: 0.8em;
    border-radius: 50%;
}

.loading-section .dot::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    animation: wave 2s ease-out infinite;
}

.loading-section .dot:nth-child(1) {
    background: var(--primery);
}

.loading-section .dot:nth-child(1)::before {
    animation-delay: 0.2s;
}

.loading-section .dot:nth-child(2) {
    background: var(--primery);
}

.loading-section .dot:nth-child(2)::before {
    animation-delay: 0.4s;
}

.loading-section .dot:nth-child(3) {
    background: var(--primery);
}

.loading-section .dot:nth-child(3)::before {
    animation-delay: 0.3s;
}

.loading-section .dot:nth-child(4) {
    background: var(--primery);
}

.loading-section .dot:nth-child(4)::before {
    animation-delay: 0.4s;
}

.loading-section .dot:nth-child(5) {
    background: var(--primery);
}

.loading-section .dot:nth-child(5)::before {
    animation-delay: 0.5s;
}

@keyframes wave {
    50%,
    75% {
        transform: scale(2.5);
    }

    80%,
    100% {
        opacity: 0;
    }
}