
.filter {
    background-color: white;
    width: 100px;
    height: 100%;
    display: inline-block;
    padding: 10px 10px 10px 10px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid var(--primery);
    border-radius: 10px;

    font-weight: 600;
    background-image: url('../icon/filter.svg');
    background-repeat: no-repeat;
    background-position-x: 0.6vw;
    background-position-y: center;
}


.filter.x {
    padding-left: 32px;
    background-image: url('../icon/x.svg');
    background-repeat: no-repeat;
    background-position-x: 10px;
    background-position-y: center;
    margin-right: 5px;
}


.filter.x::before {
    cursor: pointer;
}

.filter.x {
    cursor: auto;
}

.divider {
    /* Divider */

    width: 100%;
    height: 0.05vw;
    background: #EAECF0;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    z-index: 0;

}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.4vw;
    width: 100%;
    height: auto;
}

.form-label {
    font-size: 1.08vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: bold;
    width: 19.445vw;
    max-width: 19.445vw;
}

.form-label-subvalue {
    font-size: 1vw;
    width: 20vw;
    font-style: normal;
    font-weight: 400;
    color: #475467;

}

.form-label-help-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.1vw;
    width: auto;
}

.form-double {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1.9vw;
    width: 35.556vw;
    min-width: 30vw;
    max-width: 35.556vw;
}

.form-single {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 35.556vw;
    min-width: 30vw;
    max-width: 35.556vw;
}

.form-value {
    width: 35.35vw;
    height: 2.7vw;
}

.form-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    gap: 1.6vw 2.6vw;
    width: 100%;
    height: auto;
}

.input {

    height: 100%;
    width: 100%;
    padding: 10px 10px 10px 10px;
    border: 1px solid var(--primery);
    border-radius: 10px;
    box-sizing: border-box;
    font-weight: 400;
    color: #101828;
    transition: box-shadow 0.3s ease;
}

.input:focus {
    box-shadow: 0 0 0.4vw 0.0833vw var(--primery);
    outline: none;
}

.input.search {
    padding-left: 32px;
    background: url("../icon/search.svg") no-repeat left;
    background-position: 10px;
    background-color: white;
}


.radio-button-group {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
    align-items: flex-start;
}

.radio-button {
    display: inline-block;
    padding: 0.694444vw 2vw 0.694444vw 2vw;
    font-size: 0.972222vw;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    border: 0.06944vw solid #D0D5DD;
    border-radius: 0.5vw;
    height: auto;
    width: auto;
    font-family: 'Inter';
    font-weight: 600;
    box-sizing: border-box;
    margin-right: 2vw;
    margin-bottom: 2vw;
}

.radio-button:hover {
    background-color: #ad9ad6;
}

.radio-button input {
    display: none;
}

.radio-button.selected {
    color: #ffffff;
    border-color: #7F56D9; /* Viola */
    background-position: 1vw;

}

.radio-button.selected:hover {
    background-color: #7F56D9; /* Viola scuro */
}


.radio-button.confirm {
    background-color: #ECFDF3; /* Confermato */
    color: #067647;
    border-color: #ABEFC6;
}

.radio-button.disabled {
    background-color: #FEF3F2; /* Disabilitato */
    color: #B42318;
    border-color: #FECDCA;
}

.radio-button.change-password {
    background-color: #F9FAFB; /* Modifica Password */
    border-color: #EAECF0;
    color: #344054;
}

.radio-button.confirm:hover {
    background-color: #acf4ca;
}

.radio-button.disabled:hover {
    background-color: #fd9a91; /* Disabilitato */
}

.radio-button.change-password:hover {
    background-color: #ccced0 /* Modifica Password */
}


.radio-button.selected.confirm {
    border-width: 0.308vw;
    background-image: none;
}

.radio-button.selected.disabled {
    border-width: 0.308vw;
    background-image: none;
}

.radio-button.selected.change-password {
    border-width: 0.308vw;
    background-image: none;
}

/* The container must be positioned relative: */
.custom-select {
    position: relative;
    font-family: Arial;
    appearance: none;
    height: 100%;
    min-height: 3.056vw;
    width: 100%;
    font-size: 1.11vw;
    border: 0.0694vw solid #D0D5DD;
    border-radius: 0.5vw;
    box-sizing: border-box;
    font-family: 'Inter';
    font-weight: 500;
    color: #101828;
    cursor: pointer;
    display: inline-block;
}

.custom-select:active,
.custom-select:focus,
.custom-select:hover {

    box-shadow: 0 0 0.4vw 0.0833vw rgba(202, 143, 227, 0.7);
    outline: none; /* Rimuove l'outline predefinito del browser */
    background-color: white;
}


.custom-select select {

    display: none; /*hide original SELECT element: */

}

.custom-select.sidebar {
    min-height: 1.6vw;
    border: none;
    border-radius: 0;
}

.custom-select.sidebar:active,
.custom-select.sidebar:focus,
.custom-select.sidebar:hover {

    box-shadow: none;
    outline: none; /* Rimuove l'outline predefinito del browser */
    background-color: white;
}

.custom-select.sidebar > .select-selected {
    font-weight: bold;
}

.select-selected {

    appearance: none;
    height: 100%;
    width: 100%;
    padding: 0.74444vw 0.972222vw 0.54444vw 0.972222vw;
    font-size: 1.11vw;
    border-radius: 0.5vw;
    box-sizing: border-box;
    font-family: 'Inter';
    font-weight: 500;
    color: #101828;
    cursor: pointer;
    display: flex;
    align-items: center;
    z-index: 1;

}


/* Style the arrow inside the select element: */
.select-selected:after {
    position: absolute;
    content: ""; /* Rimuovi il contenuto precedente */
    background-size: contain; /* Assicura che l'immagine si adatti all'interno del contenitore */
    background-repeat: no-repeat; /* Assicura che l'immagine non si ripeta */
    width: 1.2vw; /* Imposta la larghezza desiderata */
    height: 1.2vw; /* Imposta l'altezza desiderata */
    margin-left: auto;
    right: 2vw;
    top: 50%;
    transform: translateY(-50%);
}

/* style the items (options), including the selected item: */
.select-items div, .select-selected {
    font-family: 'Inter';
    font-weight: 500;
    color: #101828;
    padding: 0.74444vw 0.972222vw 0.54444vw 0.972222vw;
    border: none;
    cursor: pointer;
}

/* Style items (options): */
.select-items {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    right: 0;
    z-index: 9999;
    max-height: 11vw;
    overflow-y: auto;
    border: 0.0100vw solid #cdcdcdd9;
    border-radius: 0.69444vw;
    background-color: #ffffff;
}

/* Hide the items when the select box is closed: */
.select-hide {
    display: none;
}

/* Stile delle opzioni quando passo col mouse sopra: */
.select-items div:hover,
.same-as-selected {
    background-color: rgb(219, 219, 219);
    border: none;
}

/* Aggiunta dell'icona solo quando l'opzione è selezionata: */
.select-items div.same-as-selected {
    background-color: rgba(226, 226, 226, 0.998); /* Aggiunto un colore di sfondo */
    background-repeat: no-repeat; /* Impedisci la ripetizione dell'immagine di sfondo */
    background-position: calc(100% - 1.69vw) center;


}