/* BANNER */

.banner_section_home {
    margin-top: 100px;
    position: relative;
    border-radius: 20px;
}

.banner_section_home_checkout {
    /*margin-top: 100px;*/
    position: relative;
    padding: 30px;
    border-radius: 20px;
    background-color: white;
}

.banner_section_home::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
    /*width: 1250px;*/
    /*height: 1000px;*/
    background-image: url(../images/yellow_dotes.png);
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    z-index: 0;
}

.banner_section_home .container {
    position: relative;
}

.banner_section_home .row {
    align-items: center;
}

/* hero slider text */
.banner_section_home .banner_text {
    /*  margin-top: -50px; */
}

/* hero slider heading h1 */
.banner_section_home .banner_text h1 {
    font-size: 50px;
    color: var(--dark-black);
    letter-spacing: -1.5px;
    font-weight: 700;
    margin-bottom: 20px;
}

.banner_section_home .banner_text h1 span {
    color: var(--primery);
}

.banner_section_home .banner_text .type-wrap span {
    font-weight: 700;
    color: var(--primery);
}

.banner_section_home .banner_text p {
    /*padding: 0 150px 0 0;*/
}

/* hero slider button */
.banner_section_home .app_btn {
    display: flex;
    align-items: center;
}

/* hero slider list */
.banner_section_home .app_btn li a {
    display: block;
    padding: 15px 35px;
    background-color: var(--black);
    border: none;
    position: relative;
    border-radius: 12px;
    transition: .4s all;
}

.banner_section_home .app_btn li:last-child {
    margin-left: 25px;
}

.banner_section_home .app_btn li a img {
    transition: .4s all;
    height: 36px;
}

/* hero slider users */
.banner_section_home .used_app {
    margin: 35px 0;
}

.banner_section_home .used_app ul {
    display: flex;
    align-items: center;
    margin: 0 10px 20px 0;
}

.banner_section_home .used_app ul li:not(:first-child) {
    margin-left: -20px;
}

.banner_section_home .used_app ul li:not(:first-child) img {
    border: 3px solid var(--bg-white);
    border-radius: 100px;
}

.banner_section_home .used_app p {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 0;
}

/* hero slider images */
.banner_section_home .banner_slider {
    display: flex;
    position: relative;
    z-index: 1;
}

.banner_section_home .banner_slider .left_icon {
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 9999;
}

.banner_section_home .banner_slider .right_icon {
    position: absolute;
    right: 20px;
    top: 5px;
}

/* hero slider mobile frame */
.banner_section_home .banner_slider .slider_frame {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    width: 300px;
}

.banner_section_home .banner_slider #frmae_slider {
    width: 280px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 40px;
}

.banner_section_home .banner_slider #frmae_slider::before {
    content: "";
    position: absolute;
    left: -5px;
    top: 5px;
    background-color: #fff;
    width: 100%;
    height: 90.5%;
    border-radius: 23px;
}

.banner_section_home .banner_slider::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    width: 45vh;
    height: 45vh;
    background-color: var(--primery);
    z-index: -5;
    border-radius: 30px;
}

/*@media (max-width: 768px) {
    .banner_section_home .banner_slider::before {
        left: 55%;
        top: 55%;
        width: 25vh;
        height: 25vh;
        border-radius: 67px;
    }
}*/

/* hero slider control dots */
.banner_section_home .owl-dots {
    margin-top: 40px;
}

.banner_section_home .owl-carousel .owl-item img {
    max-width: 100%;
    width: auto;
}


/* hero video */

.banner_section_home .banner_slider .video_player {
    position: relative;
    margin-left: 45px;
}

.banner_section_home .banner_slider .video_player .play_icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.banner_section_home .banner_slider .video_player .play_icon img {
    width: 120px;
    aspect-ratio: 1/1;
    opacity: 0.9;
}

.banner_section_home .banner_slider .hero_img {
    transform: translate(0%, 0%) rotate(-8deg);
    border: solid 4px var(--black);
    border-radius: 45px;
}




/* TRY CODE*/
.try_code {
    margin-top: 120px;
    margin-bottom: 120px;
    position: relative;
}

@media (max-width: 768px) {
    .try_code {
        margin-top: 100px;
        margin-bottom: 80px;
        position: relative;
    }
}

.try_code::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    background-image: url(../images/yellow_dotes.png);
    background-repeat: round;
    background-position: inherit;
    background-size: cover;
}
.try_code .qrcode-container{
    background-color: var(--primery);
}
.try_code .container {
    position: relative;
}

.try_code .row {
    align-items: center;
}

/* hero slider text */
.try_code .banner_text {
    margin-top: -20px;
    text-align: center;
}


/* hero slider heading h1 */
.try_code .banner_text h1 {
    font-size: 95px;
    color: var(--dark-black);
    letter-spacing: -1.5px;
    font-weight: 700;
    margin-bottom: 20px;
}

.try_code .banner_text p {
    padding: 0 80px;
}

.try_code .banner_text .qrcode {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}

.try_code .banner_text .qrcode p {
    padding: 10px 150px;
}

.try_code .banner_text .type-wrap span {
    font-weight: 700;
    color: var(--primery);
}


/* hero static image */

.try_code .hero_imgleft {
    position: relative;
    margin-top: 150px;
}


.try_code .hero_imgleft img{
    content: "";
    position: absolute;
    transform: translate(20%, -50%) rotate(8deg);
}

.try_code .hero_imgleft::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 46%;
    transform: translate(-50%, -50%) rotate(8deg);
    width: 230px;
    height: 230px;
    background-color: var(--black);
    z-index: -5;
    border-radius: 30px;
}

.try_code .hero_img_right{
    position: relative;
    margin-top: 0;
}

.try_code .hero_img_right img{
    content: "";
    position: absolute;
    transform: translate(16%, -60%) rotate(-8deg);
}

.try_code .hero_img_right::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 46%;
    transform: translate(-50%, -60%) rotate(-8deg);
    width: 230px;
    height: 230px;
    background-color: var(--black);
    z-index: -5;
    border-radius: 30px;
}




/* hero slider heading h2 */
.try_code .banner_text h2 {
    font-size: 40px;
    color: var(--dark-black);
    letter-spacing: -1.5px;
    font-weight: 700;
    margin-bottom: 10px;
}

.try_code .banner_text h2 span {
    color: var(--primery);
}



/* hero slider button */
.try_code .app_btn {
    display: flex;
    align-items: center;
}

/* hero slider list */
.try_code .app_btn li a {
    display: block;
    padding: 15px 35px;
    background-color: var(--black);
    border: none;
    position: relative;
    border-radius: 12px;
    transition: .4s all;
}

.try_code .app_btn li:last-child {
    margin-left: 25px;
}

.try_code .app_btn li a img {
    transition: .4s all;
    height: 36px;
}

/* hero slider users */
.try_code .used_app {
    margin: 35px 0;
}

.try_code .used_app ul {
    display: flex;
    align-items: center;
    margin: 0 10px 20px 0;
}

.try_code .used_app ul li:not(:first-child) {
    margin-left: -20px;
}

.try_code .used_app ul li:not(:first-child) img {
    border: 3px solid var(--bg-white);
    border-radius: 100px;
}

.try_code .used_app p {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 0;
}

/* hero slider images */
.try_code .banner_slider {
    display: flex;
    position: relative;
}

.try_code .banner_slider .left_icon {
    position: absolute;
    left: 70px;
    bottom: 150px;
    z-index: 9999;
}

.try_code .banner_slider .right_icon {
    position: absolute;
    right: 80px;
    top: -10px;
}

/* hero slider mobile frame */
.try_code .banner_slider .slider_frame {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    width: 300px;
}

.try_code .banner_slider #frmae_slider {
    width: 280px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 40px;
}

.try_code .banner_slider #frmae_slider::before {
    content: "";
    position: absolute;
    left: -5px;
    top: 5px;
    background-color: #fff;
    width: 100%;
    height: 90.5%;
    border-radius: 23px;
}

/*.try_code .banner_slider::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 46%;
    transform: translate(-50%, -50%) rotate(-8deg);
    width: 380px;
    height: 380px;
    background-color: var(--primery);
    z-index: -5;
    border-radius: 30px;
}*/

/* hero slider control dots */
.try_code .owl-dots {
    margin-top: 40px;
}

.try_code .owl-item img {
    max-width: 100%;
    width: auto;
}

/* OCCASIONI */


.use_case_section {
    position: relative;
}

.use_case_section .positive_inner {
    padding: 50px 0 50px 0;
    border-bottom: 1px solid var(--dark-greay);
}

.use_case_section .positive_inner .row {
    align-items: flex-start;
}

.use_case_section .positive_inner .row .sticky-top {
    top: 30px;
}

.use_case_section .google_rating {
    display: flex;
    align-items: center;
    gap: 5px;
}

.use_case_section .google_rating .star {
    display: flex;
    align-items: center;
}

.use_case_section .google_rating .star span,
.use_case_section .review_side .review_block .coustomer_info .star span {
    color: #fc9400;
}

.use_case_section .google_rating p {
    margin: 0;
    font-weight: 700;
}

.use_case_section .google_rating p img {
    height: 16px;
    nav-left: 5px;
}

.use_case_section .user_review {
    margin-bottom: 60px;
}

.use_case_section .user_review p {
    font-weight: 700;
}

.use_case_section .user_review p a {
    color: var(--primery);
}

.use_case_section .review_side .review_block {
    background-color: var(--bg-white);
    border-radius: 20px;
    padding: 50px;
    margin-bottom: 30px;
}

.use_case_section .review_side .review_block .coustomer_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 30px 0;
}

.use_case_section .coustomer_info .star {
    width: 100px;
    display: flex;
    justify-content: end;
}

.use_case_section .coustomer_info .avtar {
    display: flex;
    align-items: center;
    gap: 15px;
    width: calc(100% - 120px);
}

.use_case_section .coustomer_info .avtar img {
    width: 80px;
    aspect-ratio: 1/1;
    border-radius: 150px;
}

.use_case_section .coustomer_info .avtar .text {
    width: calc(100% - 100px);
}

.use_case_section .coustomer_info .avtar .text h3 {
    margin-bottom: 0;
}

.use_case_section .coustomer_info .avtar .text span {
    font-size: 15px;
}

.use_case_section .review_block p {
    margin: 0;
}

/* ------------inner page review Section Css Start---------- */

.use_case_section.page_ban {
    position: relative;
    padding-top: 80px;
}

.use_case_section.page_ban::before,
.use_case_section.page_ban::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    background-image: url(../images/yellow_dotes.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 40%;
    background-position: center;
    z-index: -1;
}

.use_case_section .review_side.innerpage_block {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
}

.use_case_section .review_side.innerpage_block .review_block {
    width: 48%;
}

.use_case_section .positive_inner.in_page {
    border-bottom: none;
}

.use_case_section.page_ban .google_rating {
    display: block;
}

.use_case_section.page_ban .google_rating .star {
    display: block;
}

.use_case_section.page_ban .google_rating .rate_link {
    color: var(--primery);
    font-weight: normal;
}



.use_case_section .review_grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
}

@media (min-width: 576px) {
    .use_case_section .review_grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 992px) {
    .use_case_section .review_grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

.use_case_section .review_block {
    /* background: #fff;*/
    background-color: var(--bg-white);
    border-radius: 20px;
    padding: 25px;
    margin-bottom: 30px;
    /*padding: 20px;*/
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
}

.use_case_section .coustomer_info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    flex-direction: column;
}

.use_case_section .avtar {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.use_case_section .avtar img {
    width: 100px; /* dimensione fissa per l'immagine */
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 10px;
}

.use_case_section .text h3 {
    margin: 0;
    font-size: 1.2em;
}

/* ## STEP HOME ## */
.step_home_section {
    position: relative;
    margin-top: 80px;
    /* .swiper-pagination-bullet {
         background-color: #ff0000;
     }*/

    .swiper-pagination-bullet-active {
        background-color: var(--primery);
    }

}

.step_home_section::before,
.step_home_section::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -100px;
    background-image: url(../images/yellow_dotes_tr.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 410px;
    background-position: center;
    z-index: -1;
}

.step_home_section .value_slider {
    margin-top: 50px;
}
.value_block .text-title{
    background-color: var(--primery);
}
.step_home_section .value_slider .value_block {
    background-color: var(--bg-white);
    padding: 28px;
    border-radius: 20px;
    text-align: center;
}

.step_home_section .value_slider .value_block .icon {
    width: 90px;
    aspect-ratio: 1/1;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primery);
    margin: 0 auto 30px auto;
}

.step_home_section .value_slider .value_block h3 {
    color: var(--primery);
}

.step_home_section .value_slider .value_block p {
    margin-bottom: 0;
    color: var(--black);
}

.step_home_section .owl-nav button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border-radius: 100px;
    border: 1px solid var(--greay) !important;
    transition: 0.4s all;
}

.step_home_section .owl-nav button span {
    color: var(--greay);
    font-size: 22px;
    line-height: 1.2;
}

.step_home_section .owl-nav button.owl-prev {
    left: -50px;
}

.step_home_section .owl-nav button.owl-next {
    right: -50px;
}

.step_home_section .owl-nav button:hover {
    background-color: var(--bg-white);
    border-color: var(--bg-white);
}

.step_home_section .owl-nav button:hover span {
    color: var(--primery);
}

/* our valuse home feature */

.step_home_section.home_feature {
    max-width: 1370px;
    margin: 0 auto;
    background-color: var(--dark-black);
    padding: 100px 0;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

.step_home_section.home_feature .dotes_blue {
    position: absolute;
    top: -65%;
    left: 50%;
    transform: translateX(-50%);
}

.step_home_section.home_feature::before,
.step_home_section.home_feature::after {
    content: none;
}



/* Add this CSS to your stylesheet */

@media (min-width: 1000px) {
    .mobile-view {
        display: none;
    }
}

@media (max-width: 999px) {
    .desktop-slider {
        display: none;
    }

    .mobile-view {
        display: block;
    }

    .mobile-view .value_block {
        margin-bottom: 20px;
    }
}
