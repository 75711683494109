
.purchases_section {
    position: relative;
    padding: 30px 0 !important;
}

.purchases_section .section_title {
    text-align: left;
}

.purchases_section .section_title h2 {
    font-size: 35px;
    margin: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #cfcbc4;
}

.purchases_section .filter_tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    margin: 20px 0 50px 0;
}

.purchases_section .filter_tags li a {
    display: block;
    padding: 10px 22px;
    background-color: var(--bg-white);
    border-radius: 10px;
    color: var(--text-greay);
    transition: 0.4s all;
}

.purchases_section .filter_tags li a:hover,
.purchases_section .filter_tags li a.active {
    background-color: var(--primery);
    color: var(--text-white);
}
.purchases_section {
    .blog_listings .listing_block {
        display: flex;
        padding: 30px;
        border-radius: 20px;
        background-color: var(--bg-white);
    }

    .blog_listings .listing_block:not(:last-child) {
        margin-bottom: 40px;
    }

    .blog_listings .listing_block .img {
        width: 350px;
    }

    .blog_listings .listing_block .img img {
        width: 100%;
        border-radius: 20px;
    }

    .blog_listings .listing_block .blog_text {
        width: 500px;
        padding: 0 15px 0 60px;
    }

    .blog_listings .listing_block .blog_text .tag {
        font-size: 14px;
        font-weight: 500;
        color: var(--primery);
        border: 1px solid var(--primery);
        padding: 2px 20px;
        border-radius: 100px;
    }

    .blog_listings .listing_block .blog_text h2 {
        font-size: 26px;
        font-weight: 700;
        margin: 25px 0;
    }

    .blog_listings .listing_block .blog_text h2 a {
        color: var(--dark-black);
    }

    .blog_listings .listing_block .blog_text h2 a:hover {
        color: var(--primery);
    }

    .blog_listings .listing_block .blog_text a {
        font-weight: 700;
        color: var(--primery);
        transition: 0.4s all;
    }

    .blog_listings .listing_block .blog_text a:hover {
        color: var(--dark-black);
    }

    .blog_listings .listing_block .authore_info {
        width: 250px;
    }

    .blog_listings .listing_block .authore_info {
        width: 250px;
    }

    .blog_listings .listing_block .authore_info .blog_info {
        display: flex;
        flex-wrap: wrap;
    }

    .blog_listings .listing_block .authore_info .blog_info li {
        position: relative;
        padding: 0 10px 0 15px;
        font-size: 15px;
    }

    .blog_listings .listing_block .authore_info .blog_info li::before {
        content: "";
        width: 5px;
        height: 5px;
        background-color: var(--black);
        border-radius: 10px;
        left: 0;
        position: absolute;
        top: calc(50% - 2.5px);
    }

    .blog_listings .listing_block .authore_info .avtar {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 25px;
    }

    .blog_listings .listing_block .authore_info .avtar img {
        width: 50px;
        aspect-ratio: 1/1;
        border-radius: 100px;
    }

    .blog_listings .listing_block .authore_info .avtar .text {
        width: calc(100% - 60px);
    }

    .blog_listings .listing_block .authore_info .avtar .text h3 {
        margin-bottom: 0;
        font-size: 18px;
    }

    .blog_listings .listing_block .authore_info .avtar .text span {
        font-size: 14px;
    }

    .pagination_block {
        padding: 0 15px;
        margin: 50px 0 0 0;
    }

    .pagination_block ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    .pagination_block ul li a {
        display: block;
        padding: 10px 15px;
        background-color: var(--bg-white);
        color: var(--dark-black);
        border-radius: 5px;
        transition: 0.4s all;
        line-height: 1;
    }

    .pagination_block ul li .prev,
    .pagination_block ul li .next {
        padding: 10px 20px;
    }

    .pagination_block ul li a:hover,
    .pagination_block ul li a.active {
        background-color: var(--primery);
        color: var(--text-white);
    }
    .grid_container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .card {
        cursor: pointer;
        border: 3px solid var(--primery);
        text-align: center;
        border-radius: 20px;
        display: flex;
        padding: 15px;
        align-items: center;
    }

    .qr_image img {
        width: 100%;

        object-fit: cover;
    }

    .card_content {
        margin-top: 15px;
    }

    .btn {
       /* background-color: var(--primery);*/
        color: white;
        padding: 10px;
        border: none;
        cursor: pointer;
        margin-top: 10px;

    }
}

.account_section {
    position: relative;
    padding: 80px 0 40px 0;
}

.account_section .section_title {
    text-align: left;
}

.account_section .mail_block {
    margin: 0 auto;
    background-color: var(--dark-black);
    padding: 30px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
}

.account_section .mail_block .dotes_blue {
    position: absolute;
    top: -50%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}

.account_section .mail_block .dotes_blue img {
    width: 100%;
}

.account_section .mail_block .text {
    position: relative;
    z-index: 2;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.account_section .mail_block .text .handle-account {
    display: flex;
    justify-content: space-around;
    margin-bottom: 25px;
}
.account_section .mail_block .text .handle-account-buttons {
    display: flex;
    gap: 20px;



}
.account_section .mail_block .text .handle-account-buttons .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.account_section .mail_block .text .handle-account-buttons .button-text {
    margin-top: 5px;
    font-size: 12px;
    color: white;
    text-align: center;
}
.account_section .mail_block .text .handle-account-buttons .btn {
    width: 50px;
    height: 50px;
    background-color: var(--primery);
    color: var(--text-white);
    border-radius: 10px;
    font-size: 25px;


}
.account_section .mail_block .icon {
    display: block;
    margin-bottom: 20px;
}

.account_section .mail_block h3 {
    margin-bottom: 0px;
    font-size: 26px;
}

.account_section .mail_block form .form-group {
    max-width: 100%;
    position: relative;
    margin-bottom: 5px;
}

.account_section .mail_block form .form-group .form-control {
    width: 100%;
    background-color: var(--bg-white);
    border: 1px solid var(--bg-white);
    border-radius: 10px;
    min-height: 60px;
    color: var(--dark-black);
    font-weight: 500;
}

.account_section .mail_block form .form-group button {
    position: absolute;
    right: 5px;
    top: 5px;
    background-color: var(--primery);
    color: var(--text-white);
    width: 50px;
    height: 50px;
    border-radius: 10px;
    font-size: 20px;
    transition: 0.4s all;
}

.account_section .mail_block form .form-group button:hover {
    background-color: var(--bg-white);
    color: var(--primery);
    border-color: var(--primery);
}

.account_section .mail_block form .note {
    color: #7a7a7a;
    text-align: left;
    margin: 0;
}
.event_detail_section {
    position: relative;
    padding: 80px 0 50px 0;


    .blog_head {
        margin-bottom: 45px;
    }

    .blog_head .tags_info {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .blog_head .tag {
        font-size: 14px;
        font-weight: 500;
        color: var(--primery);
        border: 1px solid var(--primery);
        padding: 2px 20px;
        border-radius: 100px;
        background-color: var(--bg-white);
    }

    .blog_head .tags_info ul {
        display: flex;
        align-items: center;
    }

    .blog_head .tags_info ul li {
        position: relative;
        padding: 0 10px 0 15px;
        font-size: 15px;
    }

    .blog_head .tags_info ul li::before {
        content: "";
        width: 5px;
        height: 5px;
        background-color: var(--black);
        border-radius: 10px;
        left: 0;
        position: absolute;
        top: calc(50% - 2.5px);
    }

    .blog_head h1 {
        font-size: 50px;
        font-weight: 700;
        margin: 15px 0 30px 0;
    }

    .blog_head .avtar {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 25px;
    }

    .blog_head .avtar img {
        width: 50px;
        aspect-ratio: 1/1;
        border-radius: 100px;
    }

    .blog_head .avtar .text {
        width: calc(100% - 60px);
    }

    .blog_head .avtar .text h3 {
        margin-bottom: 0;
        font-size: 18px;
    }

    .blog_head .avtar .text span {
        font-size: 14px;
    }
    .blog_body .content {
        display: flex;
        gap: 20px; /* Spazio tra le colonne */
    }

    .blog_body .img {

    }

    .blog_body .listings {
        flex: 2; /* Occupa più spazio */
        list-style: none; /* Rimuove i puntini */
        padding: 0;
    }

    .blog_body .listings li {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .blog_body .listings .icon {
        margin-right: 10px; /* Spazio tra l'icona e il testo */
    }

    .blog_body .img img {
        max-width: 100%;
        height: auto;
        display: block;
    }

    .blog_body .img {
        width: 65%;
        margin-bottom: 0;
    }

    .blog_body .img img {
        width: 100%;
        border-radius: 20px;
    }

    .blog_body .listings {
        padding-left: 0;
        margin: 0;
    }

    .blog_body .listings li {
        display: flex;
        align-items: flex-start;
        gap: 5px;
    }

    .blog_body .listings li .icon {
        color: var(--primery);
    }

    .blog_body .listings li p {
        margin: 0;
    }

    .blog_body h2 {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .blog_body .yt_video {
        margin: 30px 0;
    }

    .blog_body .yt_video iframe {
        width: 100%;
        aspect-ratio: 1/0.5;
        border-radius: 20px;
    }

    .blog_body .highlight_text {
        padding: 10px 0 10px 30px;
        border-left: 5px solid var(--primery);
        margin: 30px 0;
    }

    .blog_body .highlight_text h3 {
        font-weight: 600;
        line-height: 1.5;
    }

    .blog_body .social_media {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 40px;
    }

    .blog_body .social_media li a {
        width: 35px;
        height: 35px;
        border: 1px solid var(--dark-black);
        background-color: var(--dark-black);
        border-radius: 100px;
        color: var(--text-white);
        display: block;
        text-align: center;
        line-height: 35px;
        transition: 0.4s all;
    }

    .blog_body .social_media li a:hover {
        background-color: var(--bg-white);
        color: var(--dark-black);
    }

    /* comment section Css Start */
    .comment_section .section_title h2 {
        font-size: 35px;
        text-align: left;
        border-top: 2px solid var(--greay);
        padding-top: 30px;
    }

    .comment_section ul {
        margin-top: 30px;
    }

    .comment_section ul li {
        display: flex;
        align-items: center;
        padding: 30px 0;
        border-top: 1px solid var(--greay);
    }

    .comment_section ul li:last-child {
        border-bottom: 1px solid var(--greay);
    }

    .comment_section ul li.replay_comment {
        margin-left: 110px;
    }

    .comment_section ul li .authore_info {
        display: flex;
        align-items: center;
        width: 260px;
    }

    .comment_section ul li .authore_info .avtar {
        width: 88px;
        margin-right: 20px;
    }

    .comment_section ul li .authore_info .avtar img {
        width: 100%;
        aspect-ratio: 1/1;
        border-radius: 100%;
        object-fit: cover;
    }

    .comment_section ul li .authore_info .text {
        width: calc(100% - 108px);
    }

    .comment_section ul li .authore_info .text h4 {
        font-size: 20px;
        font-weight: 600;
        color: var(--dark-purple);
    }

    .comment_section ul li .authore_info .text span {
        font-size: 14px;
    }

    .comment_section ul li .comment {
        width: calc(100% - 310px);
        margin-left: 50px;
    }

    .comment_section ul li .comment p {
        margin-bottom: 0;
    }

    .comment_form_section .section_title {
        text-align: left;
    }

    .comment_form_section .section_title h2 {
        font-size: 35px;
    }

    .comment_form_section form .form-group {
        margin-bottom: 30px;
    }

    .comment_form_section form .form-group .form-control {
        border-radius: 10px;
        border: 1px solid var(--greay);
        color: var(--dark-black);
        height: 60px;
        padding: 10px 20px;
    }

    .comment_form_section form .form-group .form-control::placeholder {
        color: var(--dark-black);
    }

    .comment_form_section form .form-group textarea.form-control {
        min-height: 135px;
    }

    .comment_form_section form .puprple_btn {
        padding: 9px 50px;
    }

}

.detail_info_section {
    padding: 50px 0 0 0;
    position: relative;
    /*    padding-left: 35px !important;
        padding-right: 35px !important;*/
    .testimonial_inner {
        max-width: 100%;
        margin: 0 auto;
        background-color: var(--dark-black);
        padding: 50px 0;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
    }

    .testimonial_inner .dotes_blue {
        position: absolute;
        top: -75%;
        left: 50%;
        transform: translateX(-50%);
    }

    .title {
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        width: 100%;
        text-align: center;
    }

    .title .star {
        display: flex;
        justify-content: center;
        transform: rotate(-3deg);
        margin-bottom: -20px;
    }

    .title .star span,
    .testimonial_box .testi_text .star span {
        color: white;
    }

    .title .star .sub_title {
        padding: 2px;
    }

    .title .sub_title {
        transform: rotate(-3deg);
        position: relative;
        bottom: -20px;
        z-index: 9;
    }

    .title .sub_title::after {
        content: "";
        position: absolute;
        right: 0;
        top: -10px;
        width: 22px;
        height: 22px;
        background-image: url(../images/bigstar.png);
        background-repeat: no-repeat;
        background-size: contain;
    }

    .title .sub_title {
        color: var(--bg-white);
        display: inline-block;
        padding: 2px 20px;
        border-radius: 100px;
        background-color: var(--primery);
    }

    .testimonial_box {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: relative;
        margin: 0 auto;

    }

    .testimonial_box .testi_img {
        width: 200px;
        position: relative;
    }

    .testimonial_box .testi_img .play_icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .testimonial_box .testi_img .play_icon img {
        width: 80px;
        aspect-ratio: 1/1;
        opacity: 0.9;
    }

    .testimonial_box .testi_img .user_img {
        width: 100%;
        aspect-ratio: 1/1;
        border-radius:10%;

    }

    .testimonial_box .testi_text {
        width: 100%;
        color: white;
    }

    .testimonial_box .testi_text .star {
        display: flex;
    }

    .testimonial_box .testi_text p {
        font-weight: 600;
        font-size: 50px;
        line-height: 1.5;
    }

    .testimonial_box .user_info {
        display: flex;
        gap: 5px;
    }

    .testimonial_box .user_info h3 {
        margin: 0;
        color: var(--text-white);
    }

    .testimonial_box .user_info span {
        font-size: 15px;
    }

    .testimonial_inner .client_logo_slider {
        margin-top: 0;
        position: relative;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .testimonial_inner .client_logo_slider .img {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--bg-white);
        width: 200px;
        height: 100px;
        padding: 10px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        margin-right: 15px;
    }

    .testimonial_inner .client_logo_slider .img img {
        width: 150px;
    }
}

.detail_event_second_section {
    position: relative;
    padding: 0 0;
}

.detail_event_second_section .service_blocks {

    .btn_block.disabled .btn {
        pointer-events: none;
        opacity: 0.5;
    }
}

.detail_event_second_section .service_badge {
    background-color: var(--bg-white);
    color: var(--primery);
    padding: 5px 20px;
    border-radius: 100px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
}

.detail_event_second_section .service_badge span {
    margin-left: 3px;
}

.detail_event_second_section .service_blocks .img img {
    max-width: 100%;
    border-radius: 20px;
}

.detail_event_second_section .service_text {
    padding-right: 100px;
    p{
        margin-top: 0;
        margin-bottom: 0;
    }
}

.detail_event_second_section .service_text.right_side {

    padding-right: 0;
}

.detail_event_second_section .service_text h2 {
    font-size: 35px;
    font-weight: 700;
    color: var(--black);
    margin: 25px 0;
}

.detail_event_second_section .service_text h2 span {
    color: var(--primery);
}

.detail_event_second_section .service_text .listing_block li {
    display: flex;
    gap: 15px;
}

.detail_event_second_section .service_text .listing_block li .icon span {
    display: block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    color: var(--text-white);
    background-color: var(--dark-black);
    border-radius: 100px;
    font-size: 8px;
    margin-top: 3px;
}

.detail_event_second_section .service_text .listing_block li .text {
    width: calc(100% - 50px);
}

.detail_event_second_section .service_text .feature_list li {
    display: flex;
    align-items: start;
    gap: 5px;
}

.detail_event_second_section .service_text .feature_list li .icon span {
    color: var(--primery);
    font-size: 18px;
}

.detail_event_second_section .service_text .feature_list li p {
    margin: 0;
    padding-top: 2px;
    text-align: left;
}

.detail_event_second_section .service_text .btn_block {
    margin-top: 40px;
}

.detail_event_second_section .service_text .btn {
    text-transform: capitalize;
    font-weight: 700;
}

.foto_profilo_section {
    margin-top: 0;
    position: relative;
    /* padding: 0 0;*/
    padding: 50px 0;
}

.foto_profilo_section .section_title {
    margin-bottom: 40px;
}

.foto_profilo_section .col-md-4 {
    padding-left: 25px;
    padding-right: 25px;
}

.foto_profilo_section .experts_box {
    position: relative;
    text-align: center;
    /*    padding: 10px 10px 30px 10px;*/
    border-radius: 20px;
    background-color: transparent;
    transition: 0.4s all;
}

.foto_profilo_section .experts_box img {
    /*margin-bottom: 30px;*/
    max-width: 100%;
    border-radius: 20px;
}

.foto_profilo_section .experts_box .text h3 {
    font-size: 20px;
    font-weight: 600;
    transition: 0.4s all;
    margin: 0;
}

.foto_profilo_section .experts_box .text span {
    color: var(--black);
}

.foto_profilo_section .experts_box .social_media {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    opacity: 0;
}

.foto_profilo_section .experts_box .social_media a {
    display: block;
    width: 33px;
    height: 33px;
    border: 1px solid #cccce2;
    border-radius: 50px;
    text-align: center;
    line-height: 31px;
    color: var(--text-white);
    margin: 0 5px;
    transition: 0.4s all;
    font-size: 15px;
}

.foto_profilo_section .experts_box .social_media a {
    border-color: var(--primery);
    background-color: var(--primery);
    color: var(--text-white);
}

.foto_profilo_section .experts_box {
    background-color: white;
    box-shadow: 0 4px 10px #ede9fe;
    padding: 20px;
    .text {
        padding: 20px;
        h3 {
            color: var(--primery);
        }
    }
}

.foto_profilo_section .experts_box h3 {
    color: var(--text-white);
}

.foto_profilo_section .experts_box span {
    color: var(--text-white);
}

.foto_profilo_section .experts_box .social_media {
    opacity: 1;
}
.foto_profilo_section{
    .profile-photo, .profile-video {
        /*width: 100%; !* Riempie il contenitore *!
        height: auto; !* Mantiene le proporzioni *!
        max-height: 200px; !* Imposta un'altezza massima *!
        object-fit: cover; !* Adatta l'immagine e il video all'interno del contenitore *!*/
    }

    .fullscreen-button {
        margin-top: 10px;
        padding: 5px 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    .fullscreen-button:hover {
        background-color: #0056b3;
    }
}
.title_event_section {
    margin-top: 70px;
    position: relative;
}

.title_event_section::before {
    /* content: "";
     position: absolute;
     left: 50%;
     top: 20%;
     transform: translateX(-50%);
     width: 1250px;
     height: 1000px;
     background-image: url(../images/yellow_dotes.png);
     background-repeat: no-repeat;
     background-position: bottom center;
     background-size: contain;*/
}

.title_event_section .container {
    position: relative;
}

.title_event_section .row {
    align-items: center;
}

/* hero slider text */
.title_event_section .banner_text {
    margin-top: -50px;
}

/* hero slider heading h1 */
.title_event_section .banner_text h1 {
    font-size: 50px;
    color: var(--dark-black);
    letter-spacing: -1.5px;
    font-weight: 700;
    margin-bottom: 20px;
}

.title_event_section .banner_text h1 span {
    color: var(--primery);
}

.title_event_section .banner_text .type-wrap span {
    font-weight: 700;
    color: var(--primery);
}

/* hero slider button */
.title_event_section .app_btn {
    display: flex;
    align-items: center;
}

/* hero slider list */
.title_event_section .app_btn li a {
    display: block;
    padding: 15px 35px;
    background-color: var(--black);
    border: none;
    position: relative;
    border-radius: 12px;
    transition: 0.4s all;
}

.title_event_section .app_btn li:last-child {
    margin-left: 25px;
}

.title_event_section .app_btn li a img {
    transition: 0.4s all;
    height: 36px;
}

/* hero slider users */
.title_event_section .used_app {
    margin: 35px 0;
}

.title_event_section .used_app ul {
    display: flex;
    align-items: center;
    margin: 0 10px 35px 0;
}
.title_event_section .used_app span {
    color: var(--primery);
    font-weight: 700;
    font-size: 20px;
}
.title_event_section .used_app ul li:not(:first-child) {
    margin-left: -20px;
}

.title_event_section .used_app ul li:not(:first-child) img {
    border: 3px solid var(--bg-white);
    border-radius: 100px;
}

.title_event_section .used_app p {
    font-size: 15px;
    line-height: 19px;
    margin-bottom: 0;
}

/* hero slider images */
.title_event_section .banner_slider {
    display: flex;
    position: relative;
    overflow: hidden;
}

.title_event_section .banner_slider .left_icon {
    position: absolute;
    left: 37px;
    bottom: 150px;
    z-index: 9999;
}

.title_event_section .banner_slider .right_icon {
    position: absolute;
    right: 28px;
    top: 25px;
}

/* hero slider mobile frame */
.title_event_section .banner_slider .slider_frame {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    width: 300px;
}

.title_event_section .banner_slider #frmae_slider {
    width: 284px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 40px;
}

.title_event_section .banner_slider #frmae_slider::before {
    content: "";
    position: absolute;
    left: -5px;
    top: 5px;
    background-color: #fff;
    width: 100%;
    height: 90.5%;
    border-radius: 23px;
}

.title_event_section .banner_slider::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 46%;
    transform: translate(-50%, -50%) rotate(-8deg);
    width: 380px;
    height: 380px;
    background-color: var(--primery);
    z-index: -5;
    border-radius: 30px;
}

/* hero slider control dots */
.title_event_section .owl-dots {
    margin-top: 40px;
}
.purchases_section{

    .filters-bar{
        font-family: "Manrope", sans-serif;
        display: flex;
        justify-content: space-between;
        margin: 3px 0 25px 0;
        align-items: center;
    }
    .mainFiltersBody {
        display: flex;
        flex-direction: column;
        background-color: white;
    }

    .mainFiltersHeader {
        width: 100%;
        color: black;
        font-weight: bolder;
    }

    .contentHeaderFilters {
        width: 100%;
        margin-bottom: 1.39vw;
    }

    .mainFormFilters {
        width: 100%;
    }


    .dividerFilters {
        width: 100%;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        z-index: 0;

    }


    .form-labelFilters {
        min-width: 19.5vw;
        display: inline-grid;
        font-size: 0.972vw;
        font-weight: bolder;
    }


    .form-value-doubleFilters {
        width: 16.4vw;
        height: 2.7vw;
        margin-right: 2vw;
    }

    .form-valueFilters {
        width: 22.22vw;
        height: 2.7vw;
    }

    .form-groupFilters {
        display: flex;
        align-items: flex-start;
        margin-top: 1.5vw;
        margin-bottom: 1.5vw;
    }

    .mainFiltersBottom {
        width: 100%;
        height: 3.89vw;
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        margin-top: 2vw;
    }
}
/* RESPONSIVENESS */
@media screen and (max-width:992px) {
    .purchases_section{
        .grid_container {
            display: flex !important;
            flex-direction: column !important;
        }
    }
}

@media screen and (max-width:767px) {
    .purchases_section{
        .blog_listings .listing_block .img{
            width: 100%;

        }
        .blog_listings .listing_block .blog_text h2{
            text-align: center !important;
        }
        .blog_listings .listing_block .blog_text{
            width: 100%;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;
            h2{
                margin:10px 0px;
            }
        }
        .blog_listings .listing_block .authore_info {
            width: 100% !important;
            .info-code {
                display: flex;
            }
            .avtar{
                width: 100% !important;
                margin-top: 10px;
                margin-bottom: 20px;

            }
            .handle-qr{
                display: flex;
                flex-direction: column;
                align-items: flex-end;

            }
        }
    }
    .account_section{
        .section_title{
            text-align: center !important;
            margin-bottom: 20px;
        }
    }
    .event_detail_section{
        .blog_body .content {
            flex-direction: column;
            gap: 10px;
        }

        .blog_body .img,
        .blog_body .listings {
            flex: none;
            width: 100%;
        }
    }
    .detail_info_section{
        .testimonial_inner .client_logo_slider{
            flex-direction: column !important;
            .img{
                margin-bottom: 20px;
            }
        }
        .testimonial_box{
            align-items: normal;
        }
    }
    .detail_event_second_section{
        .service_text.right_side{
            padding-left: 0;
        }
        .service_text .btn_block{
            margin-bottom: 25px;
        }
        .service_text{
            padding-right: 0;
        }
    }
}

@media screen and (max-width:550px) {
    .purchases_section {
        .grid_container{
            h2{
                font-size: 25px !important;
            }
            .grid-date{
                font-size: 15px !important;
            }
            .btn{
                font-size: 15px !important;
                width: 60%;
            }
            .grid-column{
                padding: 0 !important;
            }
           /* .download-icon{
                width: 27% !important;
            }*/
        }
    }
}